<template>
  <div class="menu-detail-page w-100">
    <basic-subheader title="Xem chi tiết thực đơn">
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Trở về trước
        </b-button>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-3"
          type="button"
          @click="goToEditPage"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
          </span>
          Chỉnh sửa
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-detail-page__body mb-6 mt-6">
      <b-card>
        <b-overlay :show="loading">
          <div>
            <from-menu :data="detail" type="detail" :is-detail="true" />
            <template v-if="listdayfood && listdayfood.length">
              <template v-for="(dayFood, index) in listdayfood">
                <collapse-menu
                  :key="index"
                  :id="`${dayFood.dateCode}-${index}`"
                  :item="dayFood"
                  :is-detail="true"
                />
              </template>
            </template>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  GET_FOOD_TEMPLATES,
  GET_MENU_DETAIL,
} from '@/core/services/store/menu.module';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('menu');
export default {
  name: 'MenuDetail',
  components: {
    'from-menu': () => import('./components/Form.vue'),
    'collapse-menu': () => import('./components/Collapse.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
      typeModal: null,
    };
  },
  computed: {
    ...mapState(['listdayfood']),
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({ GET_FOOD_TEMPLATES, GET_MENU_DETAIL }),
    loadData() {
      if (this.id) {
        this.getMenuDetail();
      }
    },
    async getMenuDetail() {
      this.loading = true;
      const { data, error } = await this.GET_MENU_DETAIL({ id: this.id });
      if (!error) {
        this.detail = data;
        await this.GET_FOOD_TEMPLATES({
          foodMenuCode: data.code,
        });
      }
      this.loading = false;
    },
    submit(params) {
      if (this.type === 'create') {
        return this.createMenuFood(params);
      }
      params['id'] = this.id;
      return this.updateMenuFood(params);
    },
    async createMenuFood(params) {
      this.loading = true;
      try {
        let { data } = await this.$api.post('/FoodMenu', params);
        if (data.statusCode === 200) {
          return this.$emit('submit');
        }

        this.$toastr.e({
          title: 'Lỗi !',
          msg: data.message,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateMenuFood(params) {
      this.loading = true;
      try {
        let { meta, data } = await this.$api.put('/FoodMenu/Input', params);
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: data.message,
          });
        }
        return this.$emit('submit');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    returnPage() {
      this.$router.push({
        name: 'menu_list',
      });
    },
    goToEditPage() {
      this.$router.push({
        name: 'menu_edit',
        params: {
          id: this.id,
        },
      });
    },
  },
};
</script>
